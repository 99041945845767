import { getValidAccessToken } from '@/utils/oidc';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonTitle, IonToolbar, IonPage, alertController, } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    components: {
        IonButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonIcon,
        IonItem,
        IonTitle,
        IonToolbar,
        IonPage,
    },
    props: {
        appId: String,
    },
    setup() {
        const router = useRouter();
        const mailRef = ref('');
        const counter = ref(0);
        onMounted(async () => {
            const accessToken = await getValidAccessToken();
            const accessTokenContent = JSON.parse(atob(accessToken.split('.')[1]));
            const mail = accessTokenContent.email;
            counter.value++;
            mailRef.value = mail;
        });
        async function report() {
            const alert = await alertController.create({
                message: 'Sie werden auf ein LivingApps Eingabeformular umgeleitet. Sie aktzeptieren indem Sie "ok" drücken die für LivingApps geltenden <a href="https://my.living-apps.de/agb.htm">AGB</a> und <a href="https://my.living-apps.de/datenschutz.htm">Datenschutzbestimmungen</a>.',
                buttons: [
                    {
                        text: 'ok',
                        handler: () => {
                            window.location.href = "https://my.living-apps.de/gateway/apps/6088364228b18eed6c3ab2ab/new?view=6088364230d64465b03ee3c9&responsive=true";
                        }
                    },
                    {
                        text: 'Abbrechen'
                    }
                ]
            });
            alert.present();
        }
        return {
            arrowBack,
            counter,
            mailRef,
            report,
            router,
        };
    },
});
